<script>
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      salary_items: [],
      SalaryItems: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchSalaryItems: null,
      SearchMode: false,
      data: {},
      search: null,
      accountings: [],
      searchFromInput: null,
      searchToInput: null,
      froms: [],
      value: null,
      descr: null,
      // appprices:[]
    };
  },
  computed: {
    filteredFrom() {
      return this.froms
        .filter((from) => from.name.includes(this.search.toLowerCase()))
        .map((from) => from.id + "-" + "(" + from.code + ")" + "-" + from.name);
    },
  },
  methods: {
    addTransaction() {
      this.http
        .post("transactions", {
          from: this.searchFromInput.split("-")[0],
          to: this.searchToInput.split("-")[0],
          value: this.value,
          descr: this.descr,
          status: 1,
        })
        .then(() => {
          (this.searchFromInput = ""),
            (this.searchToInput = ""),
            (this.value = "");
          this.descr = "";
          this.getTransaction();
        });
    },
    searchFrom() {
      this.http
        .post("level-three-chart-of-accounts-copy-1/search", {
          search: this.searchFromInput,
        })
        .then((res) => {
          this.froms = res.data;
          console.log(this.froms);
          console.log(this.searchFromInput.split("-")[0]);
        });
    },
    searchTo() {
      this.http
        .post("level-three-chart-of-accounts-copy-1/search", {
          search: this.searchToInput,
        })
        .then((res) => {
          this.froms = res.data;
          console.log(this.froms);
        });
    },
    getTransaction() {
      this.http
        .post("transactions/pagination", {
          limit: this.limit,
          page: this.page,
        })
        .then((res) => {
          this.accountings = res.data;
        });
    },
  },
  created() {
    this.getTransaction(1);
  },
};
</script>

<template>
  <Layout>
    <PageHeader
      :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
      :title="$t('accounting_transaction.accounting-transaction')"
    />

    <form
      class="row gx-3 gy-2 align-items-center mb-4"
      @submit.prevent="addTransaction()"
    >
      <div class="col">
        <label for="from" class="form-label">{{
          $t("accounting_transaction.from")
        }}</label>
        <AutoComplete
          @keyup="searchFrom()"
          v-model="searchFromInput"
          :suggestions="filteredFrom"
          @complete="search = $event.query"
          style="color: #333 !important; border-radius: 10px !important"
        />
      </div>
      <div class="col">
        <label for="to" class="form-label">{{
          $t("accounting_transaction.to")
        }}</label>
        <AutoComplete
          @keyup="searchTo()"
          v-model="searchToInput"
          :suggestions="filteredFrom"
          @complete="search = $event.query"
          style="color: #333 !important; border-radius: 10px !important"
        />
      </div>
      <div class="col">
        <label for="value" class="form-label">{{
          $t("accounting_transaction.value")
        }}</label>
        <input
          required
          v-model="value"
          type="text"
          class="form-control"
          id="value"
        />
      </div>
      <div class="col">
        <label for="desc" class="form-label">{{
          $t("accounting_transaction.description")
        }}</label>
        <textarea
          v-model="descr"
          style="height: 45px"
          required
          class="form-control"
          id="desc"
          rows="3"
        ></textarea>
      </div>

      <div class="col-auto" style="top: 9px">
        <button
          style="height: 45px"
          type="submit"
          class="btn btn-primary mt-2 text-align-center"
        >
          {{ $t("popups.add") }}
        </button>
      </div>
    </form>
    <div class="row">
      <div class="col-6 g-0">
        <table
          style="margin-top: 29px"
          class="table"
          :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
        >
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("accounting_transaction.id") }}</th>
              <th scope="col">{{ $t("accounting_transaction.from") }}</th>
              <th scope="col">{{ $t("accounting_transaction.value") }}</th>
              <th scope="col">
                {{ $t("accounting_transaction.description") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="(account, index) in accountings"
              :key="account"
            >
              <td scope="col">{{ index + 1 }}</td>
              <td scope="col">
                {{ account?.credit_level_three_chart_of_account?.name }}
              </td>
              <td scope="col">{{ account.value }}</td>
              <td scope="col">{{ account.descr }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6 g-0">
        <table
          style="margin-top: 29px"
          class="table"
          :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
        >
          <thead>
            <tr
              class="text-light text-center"
              style="background-color: #2a3042 !important"
            >
              <th scope="col">{{ $t("accounting_transaction.to") }}</th>
              <th scope="col">{{ $t("accounting_transaction.value") }}</th>
              <th scope="col">
                {{ $t("accounting_transaction.description") }}
              </th>
              <th scope="col">
                {{ $t("accounting_transaction.payment-date") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="text-center"
              v-for="account in accountings"
              :key="account"
            >
              <td scope="col">
                {{ account?.debit_level_three_chart_of_account?.name }}
              </td>
              <td scope="col">{{ account.value }}</td>
              <td scope="col">{{ account.descr }}</td>
              <td scope="col">{{ account?.payment_date?.split("T")[0] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- end  table -->

    <!--   Apps  Pagination     -->
    <ul
      v-if="tot_pages >= 20"
      class="pagination pagination-rounded justify-content-center mb-2"
    >
      <li class="page-item" :class="{ disabled: page == 1 }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page - 1)"
          aria-label="Previous"
        >
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li
        class="page-item"
        :class="{ active: p == page }"
        v-for="p in tot_pages"
        :key="p"
      >
        <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
          p
        }}</a>
      </li>

      <li class="page-item" :class="{ disabled: page == total_pages }">
        <a
          class="page-link"
          href="javascript: void(0);"
          @click="get(page + 1)"
          aria-label="Next"
        >
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
    </ul>
    <!--end-->
  </Layout>
</template>



